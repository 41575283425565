<template>
    <div class="container-bg" style="display: block; margin: auto">
        <div class="container-fg">
            <v-overlay :absolute="true" :value="$apollo.loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <v-card style="background-color: transparent" elevation="0">
                <v-card-title
                    style="justify-content: space-between; padding-top: 0px"
                >
                    <div style="display: flex; flex-direction: row">
                        <img
                            style="width: 130px; margin-bottom: 1%"
                            src="../../assets/logos/getac-logo.svg"
                        />
                        <div
                            class="text-h7 nav-font pl-3"
                            style="
                                color: white;
                                margin-top: 3.2%;
                                font-weight: 400;
                            "
                            >VIEWING PART ORDER {{ partOrderId(local[0].uid) }}</div
                        >
                    </div>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-divider
                    style="
                        margin-bottom: 2%;
                        margin-top: 0%;
                        border-color: gray;
                    "
                />
                <details-tabs :part-order="local"></details-tabs>
                <v-card-title style="justify-content: space-between">
                    <v-btn
                        color="primary"
                        elevation="0"
                        text
                        @click="$emit('close')"
                        >Close</v-btn
                    >
                </v-card-title>
            </v-card>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="green"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import DetailsTabs from './details_tabs.vue'

export default {
    name: 'PartsOrderDetails',
    components: { DetailsTabs },
    props: {
        partOrder: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            snackbar: false,
            snackbarMessage: '',
            cancelDialog: false,
            local: {},
            batch: []
        }
    },
    watch: {
        partOrder: {
            deep: true,
            immediate: true,
            handler() {
                this.local = this.partOrder
            }
        },
        batchOrder: {
            deep: true,
            immediate: true,
            handler() {
                this.batch = this.batchOrder
            }
        }
    },
    methods: {
        confirmCancelled(order) {
            this.cancelDialog = false
            this.submitted = true
            this.$emit('cancel', order)
        },
        partOrderId(lineitem) {
            const split = lineitem.split('-')
            const batch = split[0]
            return batch
        }
    }
}
</script>

<style scoped>
.container-bg {
    color: white;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 6px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0px;
}
.container-fg {
    margin: auto;
    padding: 15px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0.096)
    );
    border-radius: 6px;
}
</style>

<style>
.v-text-field
    dark
    .v-input--dense:not(.v-text-field dark --outlined)
    .v-text-field
    dark
    __prefix,
.v-text-field
    dark
    .v-input--dense:not(.v-text-field dark --outlined)
    .v-text-field
    dark
    __suffix,
.v-text-field dark .v-input--dense:not(.v-text-field dark --outlined) input {
    text-align: center;
}
#parts-orders-data-table
    .theme--.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td,
#parts-orders-data-table
    .theme--.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#parts-orders-data-table
    .theme--.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:last-child,
#parts-orders-data-table
    .theme--.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#parts-orders-data-table
    .theme--.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border: none !important;
    background-color: transparent !important;
}
#parts-orders-data-table .theme--.v-data-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
#parts-orders-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    margin-bottom: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
#parts-orders-data-table .theme--.v-data-table {
    background-color: transparent !important;
    color: #fff;
}
#parts-orders-data-table > .v-data-table__wrapper > table {
    background-color: rgb(63, 63, 63);
}
.theme--.v-data-table .v-data-footer {
    border: none;
    background-color: rgb(63, 63, 63);
}
.expansion-header {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expansion-header-first {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expanded-panels .v-expansion-panel-content__wrap {
    padding: 0px;
}
</style>
<style scoped>
#prod-sidebar-btns {
    /* position: absolute; */
    /* transform: translate(0px, 100px); */
    border-radius: 15px;
}
.tab-fonts {
    font-weight: 400;
}
</style>

<style>
.v-tabs .modal-tab-top .theme-- {
    background-color: transparent !important;
}
.theme--.v-tabs > .v-tabs-bar {
    background-color: transparent;
}
.v-tabs-items {
    background-color: transparent;
}
.cancel-dialog {
    width: fit-content !important;
}
</style>
