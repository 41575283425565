var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{staticClass:"request-tab-top",attrs:{"dark":""}},[_c('v-tab',[_c('div',{staticClass:"text-h7 nav-font"},[_vm._v("Parts Ordered")])]),_c('v-tab',[_c('div',{staticClass:"text-h7 nav-font"},[_vm._v("Shipping")])]),_c('v-tab',[_c('div',{staticClass:"text-h7 nav-font"},[_vm._v("Tracking")])]),_c('v-tab-item',[_c('div',{staticClass:"part-order-details",staticStyle:{"background-color":"rgb(75, 75, 75)"}},[_c('div',{attrs:{"id":"card-elements"}},[_c('v-data-table',{attrs:{"id":"parts-orders-data-table","dark":"","loading":"true","headers":_vm.headers,"items":_vm.partOrder,"items-per-page":10},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}}],null,true)})],1)])]),_c('v-tab-item',[_c('div',{staticClass:"part-order-details",staticStyle:{"background-color":"rgb(75, 75, 75)"}},[_c('div',{attrs:{"id":"card-elements"}},[_c('v-row',{staticClass:"mt-1 pa-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .company,"label":"Company","outlined":"","hide-details":"","dense":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.contact
                                            .fullName,"label":"Full Name","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.contact
                                            .email,"outlined":"","label":"Email","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.contact
                                            .phone,"label":"Phone","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .line1,"label":"Address Line 1","outlined":"","hide-details":"","dense":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .line2,"label":"Address Line 2","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .city,"outlined":"","label":"City","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .state,"label":"State","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .country,"label":"Country","outlined":"","hide-details":"","dense":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].customer.shipping.address
                                            .postalCode,"label":"Postal Code","outlined":"","dense":"","hide-details":"","readonly":""}})],1)],1)],1)],1)],1)])]),_c('v-tab-item',[(_vm.local[0].tracking.outbound)?_c('div',{staticClass:"part-order-details",staticStyle:{"background-color":"rgb(75, 75, 75)"}},[_c('div',{attrs:{"id":"card-elements"}},[_c('v-row',{staticClass:"mt-1 pa-3"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].tracking.outbound.carrier,"label":"Carrier","outlined":"","hide-details":"","dense":"","readonly":""}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","value":_vm.local[0].tracking.outbound
                                            .trackingNumber,"label":"Tracking Number","outlined":"","dense":"","hide-details":"","readonly":""}})],1),_c('v-col',{staticStyle:{"padding":"20px"},attrs:{"cols":"12"}},[_c('label',{staticStyle:{"color":"white"}},[_vm._v("Tracking URL:")]),_c('br'),_c('a',{attrs:{"href":_vm.local[0].tracking.outbound
                                            .trackingUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.local[0].tracking.outbound .trackingUrl))])])],1)],1)],1)],1)]):_c('div',{staticClass:"part-order-details",staticStyle:{"background-color":"rgb(75, 75, 75)"}},[_c('div',{attrs:{"id":"card-elements"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dark":"","value":"No tracking information available"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }