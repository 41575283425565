<template>
    <v-container>
        <base-hero-simple class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <div class="container-bg">
            <div class="container-fg">
                <v-card-title style="padding: 5px 5px">
                    <div style="display: flex; flex-direction: row">
                        <img
                            style="width: 130px; margin-bottom: 1%"
                            src="../../../assets/logos/getac-logo.svg"
                        />
                        <div
                            class="text-h7 nav-font pl-3"
                            style="margin-top: 1.3%"
                            >PART ORDERS HISTORY</div
                        >
                    </div>
                    <v-spacer></v-spacer>
                    <v-text-field
                        id="data-text-field"
                        v-model="search"
                        style="
                            width: 10px;
                            margin-bottom: 15px;
                            margin-top: 15px;
                        "
                        append-icon="mdi-magnify"
                        label="Search Orders"
                        single-line
                        dense
                        hide-details
                        clearable
                        outlined
                        dark
                    ></v-text-field>
                </v-card-title>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <v-expansion-panels
                    v-model="panel"
                    class="expanded-panels"
                    dark
                    mandatory
                    accordion
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="expansion-header-first"
                            expand-icon=""
                        >
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                "
                            >
                                Viewing Order History
                            </h1>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            style="padding: 25px; border-radius: 0px"
                            class="container-fg"
                        >
                            <v-data-table
                                id="request-data-table"
                                dark
                                :server-items-length="serverItemsLength"
                                :loading="serverItemsLoading"
                                :headers="headers"
                                :items="displayedTableItems"
                                :search="search"
                                :options.sync="options"
                                :footer-props="footerOptions"
                                no-data-text="No results available"
                                no-results-text="No results available"
                                item-key="rmaBatchId"
                                @update:options="lookForMoreServerItems"
                            >
                                <template v-slot:[`item.uid`]="{ item }">
                                    <v-btn
                                        color="primary"
                                        elevation="0"
                                        outlined
                                        @click="getBatchOrder(item.rmaBatchId)"
                                        >{{ item.rmaBatchId }}</v-btn
                                    >
                                </template>
                                <template v-slot:[`item.createdAt`]="{ item }">
                                    {{
                                        new Date(
                                            item.createdAt
                                        ).toLocaleString()
                                    }}
                                </template>
                                <template v-slot:[`item.address`]="{ item }">
                                    {{ item.customer.shipping.address.line1 }},
                                    {{ item.customer.shipping.address.city }},
                                    {{ item.customer.shipping.address.state }},
                                    {{
                                        item.customer.shipping.address.country
                                    }},
                                    {{
                                        item.customer.shipping.address
                                            .postalCode
                                    }}
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
        <v-dialog v-model="dialog" content-class="detail-dialog">
            <parts-order-details
                :part-order="selectedOrder"
                @close="dialog = false"
            ></parts-order-details>
        </v-dialog>
        <v-snackbar v-model="snackbar" right>
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import PartsOrderDetails from '../../../components/parts_order_detail/index.vue'
import { navLinks } from '../navigation.js'
import gql from 'graphql-tag'
import { relayPagination } from '@/mixins/relay_pagination.js'

export default {
    metaInfo: { title: `Your Account`, subTitle: `Account Settings` },
    name: 'PartsOrdersHistory',
    components: { PartsOrderDetails },
    mixins: [relayPagination('partsOrderBatchConnection')],
    props: {},
    apollo: {
        partsOrderBatchConnection: {
            query: gql`
                query partsOrderBatchConnection(
                    $unHealthy: Boolean
                    $healthy: Boolean
                    $customerOnly: Boolean
                    $after: String
                    $before: String
                    $last: Int
                    $sort: SMSortQuery
                    $first: Int
                    $search: String
                ) {
                    partsOrderBatchConnection(
                        unHealthy: $unHealthy
                        healthy: $healthy
                        customerOnly: $customerOnly
                        after: $after
                        before: $before
                        last: $last
                        sort: $sort
                        first: $first
                        search: $search
                    ) {
                        pageInfo {
                            endCursor
                            hasNextPage
                            hasPreviousPage
                            startCursor
                        }
                        edges {
                            cursor
                            node {
                                createdAt
                                rmaBatchId
                                totalQty
                                customer {
                                    billing {
                                        address {
                                            APO
                                            company
                                            line1
                                            line2
                                            city
                                            state
                                            country
                                            postalCode
                                            international
                                        }
                                        contact {
                                            firstName
                                            lastName
                                            fullName
                                            email
                                            phone
                                            company
                                            position
                                        }
                                    }
                                    shipping {
                                        address {
                                            APO
                                            company
                                            line1
                                            line2
                                            city
                                            state
                                            country
                                            postalCode
                                            international
                                        }
                                        contact {
                                            firstName
                                            lastName
                                            fullName
                                            email
                                            phone
                                            company
                                            position
                                        }
                                    }
                                }
                                tpm
                            }
                        }
                        totalCount
                    }
                }
            `,
            debounce: 200,
            variables() {
                return {
                    first: this.options.itemsPerPage,
                    search: (this.search || ``).trim(),
                    sort: {
                        key: this.options.sortBy[0],
                        descending: this.options.sortDesc[0]
                    },
                    customerOnly: true
                }
            }
        }
    },
    data() {
        return {
            snackbar: false,
            panel: 0,
            dialog: false,
            search: '',
            snackbarMessage: '',
            selectedOrder: {},
            selectedBatch: [],
            groupedOrders: [],
            links: navLinks,
            customerOrders: [],
            footerOptions: {
                itemsPerPageOptions: [10, 25, 50]
            },
            options: {
                sortBy: ['createdAt'],
                sortDesc: [true],
                itemsPerPage: 10,
                page: 1
            },
            headers: [
                {
                    text: 'Parts Order',
                    value: 'uid',
                    align: 'center',
                    sortable: false
                },
                {
                    text: 'Customer Name',
                    value: 'customer.shipping.contact.fullName',
                    sortable: false
                },
                {
                    text: 'Address',
                    value: `address`,
                    sortable: false
                },
                {
                    text: 'Create Date',
                    value: 'createdAt',
                    sortable: false
                },
                {
                    text: '# of Items',
                    align: 'center',
                    value: 'totalQty',
                    sortable: false
                }
            ]
        }
    },
    created() {},
    methods: {
        getBatchOrder(batchId) {
            this.$apollo
                .query({
                    query: gql`
                        query GetPartsOrderDetail($orderBatchId: String!) {
                            getPartsOrderDetail(orderBatchId: $orderBatchId) {
                                version
                                uid
                                userId
                                metadata {
                                    health {
                                        atCreateMoveAttachments {
                                            applied
                                            error
                                        }
                                        atCreateECN {
                                            applied
                                            error
                                        }
                                        atCreateShipping {
                                            applied
                                            error
                                        }
                                        atCreateRSPL {
                                            applied
                                            error
                                        }
                                        atCreateTPM {
                                            applied
                                            error
                                        }
                                        atCreateSendConfEmail {
                                            applied
                                            error
                                        }
                                    }
                                    bouncer
                                    doa
                                    reportedIncorrectly
                                    rulesApplied {
                                        name
                                        index
                                    }
                                    overrides {
                                        tpm
                                        targetUser
                                        skipEmail
                                        shippingMethod
                                        explanation
                                        overriddenBy
                                    }
                                }
                                stage {
                                    created {
                                        postedAt
                                        postedBy
                                        tpmCreatedAt
                                        appliedPn
                                        appliedQty
                                    }
                                    reported {
                                        postedAt
                                        postedBy
                                        tpmCreatedAt
                                    }
                                    closed {
                                        postedAt
                                        postedBy
                                        tpmCreatedAt
                                    }
                                    shipped {
                                        postedAt
                                        postedBy
                                        tpmCreatedAt
                                        shippedPn
                                        shippedQty
                                    }
                                    cancelled {
                                        postedAt
                                        postedBy
                                        tpmCreatedAt
                                    }
                                }
                                createdAt
                                closedAt
                                tracking {
                                    inbound {
                                        uid
                                        service
                                        created
                                        carrier
                                        labelUrl
                                        trackingNumber
                                        trackingUrl
                                        commercialInvoiceUrl
                                        sent
                                        received
                                        eta
                                        reference
                                    }
                                    outbound {
                                        uid
                                        service
                                        created
                                        carrier
                                        labelUrl
                                        trackingNumber
                                        trackingUrl
                                        commercialInvoiceUrl
                                        sent
                                        received
                                        eta
                                        reference
                                    }
                                    box {
                                        uid
                                        service
                                        created
                                        carrier
                                        labelUrl
                                        trackingNumber
                                        trackingUrl
                                        commercialInvoiceUrl
                                        sent
                                        received
                                        eta
                                        reference
                                    }
                                }
                                rmaBatchId
                                rmaType
                                unit {
                                    serial
                                    warrantyType
                                    endDeviceWarranty
                                    endBatteryWarranty
                                    endAccessoryWarranty
                                    shipDate
                                    model
                                    mfgModel
                                    deviceSpecs {
                                        biosVersion
                                        fullDescription
                                        hdiVersion
                                        hdiPart
                                        imei
                                        description
                                        pn
                                        mfgPn
                                        motherboardPn
                                        motherboardSerial
                                    }
                                    sla {
                                        tat
                                        shippingMethod
                                        keepHd
                                        zeroDefect
                                        lcdUpgrade
                                        saltFog
                                        cosmeticUpgrade
                                        fodUpgrade
                                        emi
                                    }
                                    maxSMQty
                                }
                                smId
                                category
                                partSpec
                                status
                                closeReason
                                partDescription
                                tpm
                                permissions {
                                    reimage
                                    biosFirmware
                                    userId
                                    userPassword
                                    biosPassword
                                }
                                customer {
                                    shipping {
                                        address {
                                            APO
                                            company
                                            line1
                                            line2
                                            city
                                            state
                                            country
                                            postalCode
                                            international
                                        }
                                        contact {
                                            firstName
                                            lastName
                                            fullName
                                            email
                                            phone
                                            company
                                            position
                                        }
                                    }
                                }
                            }
                        }
                    `,
                    variables: {
                        orderBatchId: batchId
                    }
                })
                .then((data) => {
                    console.log(data)
                    const partOrder = data.data.getPartsOrderDetail
                    this.selectedOrder = partOrder
                    this.dialog = true
                })
                .catch((errors) => {
                    console.log(errors)
                })
        }
    }
}
</script>

<style>
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:last-child,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border: none !important;
    background-color: transparent !important;
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
#request-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    margin-bottom: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    color: #fff;
}
#request-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 0.5rem;
    background-color: rgb(63, 63, 63);
}
.theme--dark.v-data-table .v-data-footer {
    border: none;
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    /* display: none; */
}
.expansion-header {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expansion-header-first {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
}
.expanded-panels .v-expansion-panel-content__wrap {
    padding: 0px;
}
.v-tabs .request-tab-top .theme--dark {
    background-color: transparent !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: transparent;
}
</style>
